document.addEventListener('turbolinks:load', function () {
    if ($('.del_rio_fees_calculator.index').length === 0) {
        return false
    }

    let developmentServiceSelect = $('#development-service')
    let constructionTypeSelect = $('#construction-type')
    let typeSelect = $('#type')
    let feeSelect = $('#fee')

    let typeSelectSection = $('#select-type')
    let feeSelectSection = $('#select-fee')
    let permitDetailSection = $('.permit-detail-section')
    let resultSection = $('#result')
    $(document).on('change', '#development-service', function () {
        let value = $(this).val()
        $(typeSelectSection).addClass('d-none')
        $(feeSelectSection).addClass('d-none')
        $(permitDetailSection).addClass('d-none')
        clearResult()
        getConstructionTypes(value)
    })


    function getConstructionTypes(developmentService) {
        $.ajax({
            url: "/fees/del_rio/construction_types",
            data: {
                development_service: developmentService
            },
            method: 'GET'
        }).done(function (res) {
            if (res.error) {
                alert(res.error)
            } else {
                let constructionTypes = res.result
                populateConstructionTypes(constructionTypes)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function populateConstructionTypes(types) {
        let defaultSelect = 'Choose construction type'
        let gridElement = $('#select-construction-type')
        addSelectOptions(types, defaultSelect, constructionTypeSelect, gridElement)
    }

    $(document).on('change', '#construction-type', function () {
        let value = $(this).val()
        let developmentService = $(developmentServiceSelect).val()
        $(feeSelectSection).addClass('d-none')
        $(permitDetailSection).addClass('d-none')
        clearResult()
        getTypes(developmentService, value)
    })

    function getTypes(developmentService, constructionType) {
        $.ajax({
            url: "/fees/del_rio/types",
            data: {
                development_service: developmentService,
                construction_type: constructionType
            },
            method: 'GET'
        }).done(function (res) {
            if (res.error) {
                alert(res.error)
            } else {
                let types = res.result
                populateTypes(types)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function populateTypes(types) {
        let defaultSelect = 'Choose type'
        let gridElement = $('#select-type')
        addSelectOptions(types, defaultSelect, typeSelect, gridElement)
    }

    $(document).on('change', '#type', function () {
        let value = $(this).val()
        let developmentService = $(developmentServiceSelect).val()
        let constructionType = $(constructionTypeSelect).val()
        $(permitDetailSection).addClass('d-none')
        clearResult()
        getFees(developmentService, constructionType, value)
    })

    function getFees(developmentService, constructionType, type) {
        $.ajax({
            url: "/fees/del_rio/fees",
            data: {
                development_service: developmentService,
                construction_type: constructionType,
                type: type
            },
            method: 'GET'
        }).done(function (res) {
            if (res.error) {
                alert(res.error)
            } else {
                let fees = res.result
                populateFees(fees)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function populateFees(fees) {
        let defaultSelect = 'Choose fee'
        let gridElement = $('#select-fee')
        addSelectOptions(fees, defaultSelect, feeSelect, gridElement)
    }

    function addSelectOptions(options, defaultSelect, selectElement, gridElement) {
        $(selectElement).empty()
        $(selectElement).append($('<option>', {
            value: null,
            text: defaultSelect,
            disabled: true,
            selected: true
        }));
        $.each(options, function (index, val) {
            $(selectElement).append(new Option(val, val));
        });
        $(gridElement).removeClass('d-none')
    }

    $(document).on('change', '#fee', function () {
        let value = $(this).val()
        let developmentService = $(developmentServiceSelect).val()
        let constructionType = $(constructionTypeSelect).val()
        let type = $(typeSelect).val()
        clearResult()
        getPermitDetail(developmentService, constructionType, type, value)
    })

    function getPermitDetail(developmentService, constructionType, type, fee) {
        $.ajax({
            url: "/fees/del_rio/permit_details",
            data: {
                development_service: developmentService,
                construction_type: constructionType,
                type: type,
                fee: fee
            },
            method: 'GET'
        }).done(function (res) {
            if (res.error) {
                alert(res.error)
            } else {
                let permitDetail = res.result
                populatePermitDetail(permitDetail)
            }
        }).fail(function (res) {
            alert('Something went wrong')
        });
    }

    function populatePermitDetail(permitDetail) {
        cleanPermitDetailSection()
        let frequency = permitDetail.frequency
        let unit = permitDetail.unit
        let questionId = permitDetail.question_id
        let question = $('.permit-detail-section .question')
        let frequencyTitle = $('.permit-detail-section .frequency-title')
        let unitTitle = $('.permit-detail-section .unit-title')

        // hide all questions before showing data specific question
        $(question).addClass('d-none')
        if (questionId?.length> 0) {
            $(`#${questionId}`).removeClass('d-none')

            // show unit header which could have been hidden
            $('.unit-header').removeClass('d-none')
            // show add unit button which could have been hidden
            $('.add-unit-btn').removeClass('d-none')

            $(frequencyTitle).html(frequency)
            $(unitTitle).html(unit)
            $(permitDetailSection).removeClass('d-none')
        } else if (frequency?.length > 0) {
            // hide unit header since it is not needed if there are no questions
            $('.unit-header').addClass('d-none')

            // hide add unit btn since there is no use of it
            $('.add-unit-btn').addClass('d-none')
            $('#default_question label').html(`Number of ${unit}`)
            $('#default_question').removeClass('d-none')

            $(frequencyTitle).html(frequency)
            $(unitTitle).html(unit)
            $(permitDetailSection).removeClass('d-none')
        }
    }

    // clear all cloned form before showing permit detail form for new selected data
    function cleanPermitDetailSection() {
        let cloningForm = $('.permit-detail-section .wrapper').find(".cloningForm")
        $(cloningForm).not(":first").remove();
        $(cloningForm).find(".cloningUnitForm").not(":first").remove();
        $(permitDetailSection).addClass('d-none')
    }

    function clearResult() {
        $(resultSection).empty()
    }
})