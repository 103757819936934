document.addEventListener('turbolinks:load', function () {
    if ($('.del_rio_fees_calculator.index').length === 0) {
        return false
    }

    $(document).on('click', '.add-frequency-btn', function (e) {
        e.preventDefault()

        let cloningForm = $('.cloningForm')
        let lastForm = $(cloningForm).last()
        let lastFormId = lastForm.attr('id')
        let clone = lastForm.clone(true)

        // Remove all units except the first one
        clone.find(".cloningUnitForm").not(":first").remove();

        emptyClonedForm(clone)

        // changing name attribute for cloned form field to make it easier to store data
        // on the database
        let newCount = $(cloningForm).length + 1
        changeInAttribute(clone, lastFormId, newCount, 'frequency');

        let removeBtn = $(clone).find(".remove-occurrence-icon")
        showRemoveButton(removeBtn)

        $(clone).find('.frequency-count').html(newCount)
        $(clone).appendTo('.wrapper')
    })

    $(document).on('click', '.add-unit-btn', function (e) {
        e.preventDefault()

        let cloningForm = $(this).parents('.cloningForm').find('.cloningUnitForm')
        let lastForm = $(cloningForm).last()
        let lastFormId = lastForm.attr('id')
        let clone = lastForm.clone(true)

        emptyClonedForm(clone)

        // changing name attribute for cloned form field to make it easier to store data
        // on the database
        let newCount = $(cloningForm).length + 1
        changeInAttribute(clone, lastFormId, newCount, 'unit');

        let removeBtn = $(clone).find(".remove-unit-icon")
        showRemoveButton(removeBtn)

        let unitWrapper = $(this).parents('.cloningForm').find('.unit-wrapper')
        $(clone).find('.unit-count').html(newCount)
        $(clone).appendTo(unitWrapper)
    })

    function emptyClonedForm(clone) {
        $(clone).find("input").each((i, field) => {
            $(field).val('')
        })
    }

    function changeInAttribute(clone, lastFormId, newCount, type) {
        $(clone).find("input,select").each((i, field) => {
            if (type === 'frequency') {
                field.name = field.name.replace(`${type}[${lastFormId}]`, `${type}[${newCount}]`);
            } else {
                field.name = field.name.replace(`[${type}][${lastFormId}]`, `[${type}][${newCount}]`);
            }
        })
        $(clone).attr('id', newCount.toString())
    }

    function showRemoveButton(removeBtn) {
        if (removeBtn.hasClass('d-none')) {
            removeBtn.removeClass('d-none')
        }
    }

    $(document).on('click', '.remove-occurrence-icon', function () {
        $(this).closest('.cloningForm').remove()
        resetNames()
    })

    $(document).on('click', '.remove-unit-icon', function () {
        $(this).closest('.cloningUnitForm').remove()
        resetNames()
    })

    function resetNames() {
        $('.cloningForm').each((frequencyIndex, form) => {
            const newFrequencyIndex = frequencyIndex + 1;
            $(form).find('.cloningUnitForm').each((unitIndex, unitForm) => {
                const newUnitIndex = unitIndex + 1;
                $(unitForm).find("input,select").each((inputIndex, field) => {
                    let questionPart = field.name.split('[')[4]
                    let cleanQuestion = questionPart.replace(']', '')
                    field.name = `frequency[${newFrequencyIndex}][unit][${newUnitIndex}][${cleanQuestion}]`
                })
                $(unitForm).find('.unit-count').html(newUnitIndex)
                $(unitForm).attr('id', newUnitIndex.toString())
            })
            $(form).find('.frequency-count').html(newFrequencyIndex)
            $(form).attr('id', newFrequencyIndex.toString())
        })
    }
})